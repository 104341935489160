.component-display {
    background-color: #f3f3f3;
    color: #222;
    text-align: right;
    font-weight: 200;
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 2px;
    border-radius: 3px;
}

.component-display .component-display-result {
    font-size: 65px;
    padding: 20px 35px 5px 25px;
    overflow: auto;
    white-space: nowrap;
}

    .component-display .component-display-history {
        position: absolute;
        background-color: transparent;
        color: #333;
        top: 2px;
        left: 4px;
        width: calc(100% - 4px);
        text-align: left;
        font-size: 24px;
        white-space: nowrap;
        overflow-x: auto;
    }

        .component-display .component-display-history::-webkit-scrollbar, .component-display .component-display-result::-webkit-scrollbar {
            width: 5px;
            height: 8px;
            background-color: transparent;
        }

        .component-display .component-display-history::-webkit-scrollbar-thumb, .component-display .component-display-result::-webkit-scrollbar-thumb {
            background: #aaa;
            opacity: 0.5;
            border-radius: 3px;
        }

.component-display .component-display-unit {
    position: absolute;
    opacity: 0.5;
    background-color: transparent;
    color: #777;
    top: 0px;
    right: -30px;
    width: 100px;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
}

.component-display .component-display-exception {
    display: none;
    color: #ff9999;
    font-size: 20px;
    padding: 6px 35px 6px 25px;
}

.component-display.exception .component-display-result {
    color: orangered;
    font-size: 47px;
}

.component-display.exception .component-display-exception {
    display: block;
}

@media (max-width: 400px) {
    .component-display div.component-display-result {
        font-size: 50px;
        padding-top: 10px;
    }
    .component-display .component-display-history {
        font-size: 16px;
    }
}

@media (min-width: 400px) and (max-width: 600px) {
    .component-display div.component-display-result {
        font-size: 70px;
        padding-top: 18px;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    .component-display div.component-display-result {
        font-size: 100px;
    }
    .component-display .component-display-history {
        font-size: 20px;
    }
}
