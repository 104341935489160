$bg-dark-op05: rgba(0, 0, 0, 0.5);
$bg-dark-op0: rgba(0, 0, 0, 0);
$bgGrey: #c1c1c1;
$bgLightGrey: #ebecec;
$bgDisabled: #c1c1c1;
$lightGrey: #f0f1f1;
$greenColor: green;
$orangeColor: orange;
$redColor: red;
$pink: #f50257;
$bgPink: #ea83b3;
$border-radius: 8px;
$btn-icon-size: 20px;
$primary: #0078be;
$primary-light: #e5eff5;
$text-color-dark-blue: #064a73;
$text-color-darker-blue: #074a73;
$text-color-gray: #898989;
$bgBlue: #d6eef9;
$bgOrange: #ffe9cd;
$introStepsPageWidth: 1096px;
$introStepsPageWidthSmall: 796px;
$snapshotAreaWidth: 500px;
$snapshotAreaWidthSmall: 400px;
$snapshotAreaHeight: 375px;

// New UI Kit
$background: #f5f5f5;
$progressIndicator: #0fa4ec;
$progressBaseline: #d0d5de;

$grey25: #fcfcfd;
$grey50: #f9fafb;
$grey100: #f2f4f7;
$grey200: #eaecf0;
$grey300: #d0d5dd;
$grey400: #98a2b3;
$grey500: #667085;
$grey600: #475467;
$grey700: #344054;
$grey800: #1d2939;
$grey900: #101828;

$primary25: #f5fbff;
$primary50: #f0f9ff;
$primary100: #e0f2fe;
$primary300: #7cd4fd;
$primary500: #0ba5ec;
$primary600: #0086c9;
$primary700: #026aa2;

$error25: #fffbfa;
$error50: #fef3f2;
$error100: #fee4e2;
$error300: #fda29b;
$error500: #f04438;
$error600: #d92d20;
$error700: #b42318;

$warning25: #fffcf5;
$warning50: #fffaeb;
$warning100: #fef0c7;
$warning300: #f79009;
$warning500: #dc6803;
$warning600: #d92d20;
$warning700: #b54708;

$shadowLg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
  0px 12px 16px -4px rgba(16, 24, 40, 0.08);
$shadowMd: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
  0px 4px 8px -2px rgba(16, 24, 40, 0.1);

.textMdMedium {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.textMdNormal {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.textXsMedium {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.textXsNormal {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.textSmNormal {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.textSmSemibold {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.textMdBold {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.textMdSemibold {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.textSmMedium {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.textLgMedium {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.textXlBold {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.header2Semibold {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}

.width500 {
  width: 500px !important;
}

.width682 {
  width: 628px !important;
}
