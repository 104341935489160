﻿$page-background-color: #f8f8fa;

$color-magenta: #e5097f;

$breakpoints: (
    'small': (min-width: 992px),
    'small-height': (max-height: 520px),
    'medium': (min-width: 1024px),
    'medium-height': (max-height: 780px),
    'normal': (min-width: 1200px),
    'large': (min-width: 1340px)
);

$breakpointsRange: (
    'small': '(min-width: 992px) and (max-width: 1199px)',
    'normal': '(min-width: 1200px) and (max-width: 1339px)'
);

$container-widths: (
    small: $px-800,
    normal: $px-960,
    large: $px-1140
);

$highlighter-selected-color: #fef0c7;

$header-min-height: 70px;
$buttons-row-height: 70px;

// $fixedComponentsHeight = #header.minHeight + #navigation-row.minHeight + #buttons-row.minHeight
// #navigation-row height isn't fixed = minimal height is 55px
// current value is 195px minus extra 5px as a buffer
$fixedComponentsHeight: calc($header-min-height + 55px + $buttons-row-height - 5px);

$item-icon-border: 1px $gray-400 solid; 
$item-icon-size: $px-32;

// animation
@keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
}

$spin-linear: spin 1.5s linear infinite;