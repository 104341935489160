﻿.item-content {
  .multiple-choice-item {
    .options {
      border: 0px solid black;

      &:has(.horizontal-item) {
        display: flex;
      }
    }

    .option-item {
      position: relative;
      margin-top: $px-8;
      padding: $px-5;
      display: flex;
      border-radius: $px-5;
      align-items: center;

      input[type='radio'],
      input[type='checkbox'] {
        position: absolute;
        visibility: hidden;

        & + label {
          cursor: pointer;
        }
      }

      input[type='checkbox']:checked {
        background-color: $primary-600;
        border-radius: 50%;
        border: 1px solid $primary-600;
        z-index: 101;
        color: $white;
        text-align: center;
        visibility: hidden;
        position: relative;
        height: 0;

        &:after {
          content: attr(key);
          display: flex;
          justify-content: center;
          align-items: center;
          width: $item-icon-size;
          height: $item-icon-size;
          position: absolute;
          left: 0;
          top: -1rem;
          background-color: $primary-500;
          border-radius: 50%;
          z-index: 102;
          color: $white;
          font-size: $px-16;
          text-align: center;
          visibility: visible;
        }

        & + label {
          &:before {
            @include x-close('center');
          }
          p {
            margin-left: 0.188rem;
          }
        }
      }

      .simple-text-item {
        margin: 0;
        padding: 0;
        font-weight: normal;
      }

      .itemOptionLabel {
        margin-bottom: 0;
        margin-left: $item-icon-size;

        .itemOptionKey {
          width: $item-icon-size;
          height: $item-icon-size;
          display: flex;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: $item-icon-border;
          font-size: $px-16;
          font-weight: 600;
          color: $gray-400;
          z-index: 100;
          position: absolute;
          top: calc(50% - 16px);
          left: $px-5;
        }

        P {
          margin-left: $px-16;
          padding: 0;
        }
      }

      .clearAnswer {
        position: absolute;
        width: calc(100% - $px-10);
        height: calc(100% - $px-10);
        color: $white;
        cursor: pointer;

        &::before {
          content: attr(value);
          position: absolute;
          top: calc(50% - 16px);
          display: flex;
          justify-content: center;
          align-items: center;
          width: $item-icon-size;
          height: $item-icon-size;
          border-radius: 50%;
          border: 1px solid $primary-500;
          background-color: $primary-500;
          z-index: 101;
          color: $white;
        }

        &:after {
          @include x-close('center', 5);
        }

        &.clearAnswer-inline {
          &::before {
            top: 0;
          }

          &:after {
            top: 6px;
          }
        }
      }
    }

    &.multiple-choice-item-row {
      width: 100%;

      .assignment {
        display: inline-block;
        padding: 5px 10px 5px 50px !important;
      }

      .options {
        padding-top: 0;
        display: inline-block;
        text-align: right;

        .horizontal-item {
          padding-left: 65px;
          min-width: 70px;

          input {
            position: initial;
          }

          p {
            display: inline-block;
          }
        }
      }
    }
  }

  &.hide-distractor-keys {
    .multiple-choice-item {
      .option-item {
        /* radio button */
        .clearAnswer {
          &::before {
            content: '';
            border: 9px $primary-600 solid;
            background-color: $white;
          }
        }

        .itemOptionLabel {
          &.inline-item {
            display: grid;

            .itemOptionKey {
              grid-column: 1 / 2;
            }

            P {
              grid-column: 2 / 2;
            }
          }

          .itemOptionKey {
            font-size: 0;
            color: transparent;
            border: 1px $gray-400 solid;
          }
        }

        /* checkbox */
        input[type='checkbox'] + label > .itemOptionKey {
          border-radius: $px-6;
        }

        input[type='checkbox']:checked {
          &:after {
            content: '';
            border-radius: $px-6;
            border-color: $primary-600;
            @include base64image('check');
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        input[type='checkbox']:checked + label {
          &:before {
            display: none;
          }

          > .itemOptionKey {
            background: none;
          }
        }
      }
    }
  }
}
