@import 'uiKit';

// Above .showTestBar OR .showTestApp component START
.screenPlayback {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  bottom: 0;
  left: 0;
}

.notistack-SnackbarContainer {
  bottom: 70px !important;
  .notistack-MuiContent {
    display: flex;
    align-items: center;
    div:first-child {
      flex: 1;
    }
    .closeBtn {
      margin-right: 10px;
      cursor: pointer;
    }
    &.error {
      background-color: $error100 !important;
      color: $error700 !important;
    }
    &.information {
      background-color: $grey800 !important;
      color: $grey25 !important;
    }
  }
}

// EXAM WATCHER
#exam-watcher {
  position: fixed;
  cursor: move;
  z-index: 1000;
  width: 118px;
  left: 10px;
  bottom: 80px;
  padding: 0;
  color: $text-color-dark-blue;
  background: white;
  border-radius: 4px;
  font-size: 0.625rem;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  height: fit-content;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: $grey700;
    padding-top: 8px;

    .expandBtn,
    .playbackDisplayBtn {
      cursor: pointer;

      svg {
        font-size: 20px;
        fill: $grey700;
      }
    }
  }

  &.roomStateError {
    .header {
      color: $error700;
      background-color: $error100;

      .playbackDisplayBtn {
        svg {
          fill: $error700;
        }
      }
    }
    .violation-list {
      background-color: $error100;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $error700;
        border-radius: 100vw;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      p {
        span {
          color: $error700 !important;
        }
      }
    }
  }

  .hidePlayback {
    position: absolute;
    left: -1000000px;
  }

  .error {
    padding: 8px 8px 8px 26px;
    position: relative;
    font-weight: bold;
    background-color: $error100 !important;
    color: $error700 !important;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 16px;
    border-radius: 0;
    border: 0;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 8px;
      top: calc(50% - 6px);
      background: url(../assets/error-circle.svg);
    }

    .textButton {
      margin-left: 5px;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  video {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .violation-list {
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 35px 0 5px;
    border-radius: 0 0 4px 4px;

    &.scrollbar {
      cursor: auto;
      > * {
        cursor: move !important;
      }
    }

    &.expanded {
      max-height: 210px;
    }

    p {
      display: flex;
      margin: 0 auto 15px auto;
      span {
        color: $grey700 !important;
        padding: 10px 3px 0 3px;
        text-align: left;
      }
      .icon {
        display: block;
        height: 20px;
        width: 25px;
        padding: 3px 3px 0 3px;
        position: relative;
        z-index: 10;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.showOkMessage {
      p {
        &:first-child {
          span {
            color: $text-color-dark-blue;
          }
        }
      }
    }
  }

  @media (min-width: 1110px) and (max-width: 1199px) {
    width: 164px;
    font-size: 0.75rem;

    .error {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 1270px) and (max-width: 1339px) {
    width: 164px;
    font-size: 0.75rem;

    .error {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 1440px) {
    width: 164px;
    font-size: 0.75rem;

    .error {
      font-size: 0.75rem;
    }
  }
}

.dev-buttons {
  font-size: 11px;
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 10000;
}

.MuiMenuItem-root {
  font-size: 14px !important;
  &:hover {
    background-color: $grey50 !important;
  }
  &.Mui-selected {
    background-color: $grey50 !important;
    background: url(../assets/select-active.svg) no-repeat right 18px center;
  }
}

.MuiPaper-root {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08) !important;
  border: 1px solid $grey100 !important;
  margin-top: 6px;
}

// Above .showTestBar OR .showTestApp component END

// SHOW TEST APP WRAPPER
.showTestApp {
  .headerIntroChecks {
    display: none !important;
  }

  .proctoringLoader {
    text-align: center;
  }
}

// SHOW TEST BAR WRAPPER
.showTestBar {
  text-align: center;
  $sidebarWidthAndGap: 80px;

  #TestApp {
    display: none !important;
  }

  #sidebar {
    @media (max-width: 1280px) {
      right: calc(50vw - ($introStepsPageWidthSmall / 2 + $sidebarWidthAndGap));
    }

    @media (min-width: 1281px) and (max-width: 1339px) {
      right: calc(50vw - ($introStepsPageWidth / 2 + $sidebarWidthAndGap));
    }
  }

  // GENERAL CSS
  .cursorPointer {
    cursor: pointer;
  }

  .MuiFormControl-root {
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
    .MuiSvgIcon-root {
      position: absolute;
      right: 12px;
      pointer-events: none;
      margin: -4px 0 0 0;
      fill: $grey500;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $grey300 !important;
    }

    .MuiFormLabel-root {
      top: -15px;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      color: $grey700;
      left: -10px;
      display: none;
    }

    legend {
      display: none;
    }

    .MuiInputBase-root {
      border-radius: 8px !important;
    }
  }

  .displayFlex {
    display: flex;
  }

  .justifyContentCenter {
    justify-content: center;
  }

  .flexReverse {
    flex-direction: row-reverse;
  }

  .width530 {
    width: 530px;
    margin: auto;
  }

  .plain {
    background: transparent !important;
    border: none !important;
    outline: none;
  }

  .floatLeft {
    float: left;
  }

  .alignLeft {
    text-align: left;
  }

  .bgActive {
    background-color: $primary600;
    color: white;
    border: 1px solid $primary600;
  }

  .bgActiveLight {
    background-color: $primary50;
    color: $primary700;
    border: 1px solid $primary50;
  }

  .bgActiveReverse {
    background-color: $primary50;
    color: $primary600;
  }

  .bgDisabled {
    background-color: $grey100;
    color: $grey400;
  }

  .bgError {
    background-color: $error600;
    color: white;
    border: 1px solid $error600;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  ul,
  ol {
    margin: 0;
    line-height: 10px;
    text-align: left;
    li {
      line-height: 22px;
    }
  }

  .mt25 {
    margin-top: 25px;
  }

  .proctoringLoader {
    text-align: center;
  }

  button:disabled {
    cursor: not-allowed;
  }

  .scrollbar {
    &::-webkit-scrollbar {
      display: inline !important;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #ddd;
    }

    &::-webkit-scrollbar-thumb {
      background: #aaa;
      border-radius: 100vw;
    }
  }

  .MuiCheckbox-colorSecondary {
    .MuiSvgIcon-root {
      // transform: scale(1);
      fill: transparent;
      border: 1px solid gray;
      border-radius: 3px;
    }

    &.Mui-checked {
      .MuiSvgIcon-root {
        transform: scale(1.5);
        fill: $pink;
        border: none;
        margin: 1px;
      }
    }
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    color: $primary600 !important;
    transform: scale(0.75);
    svg {
      fill: white !important;
    }
  }
  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: $primary600 !important;
    transform: scale(0.75);
    svg {
      fill: $primary600 !important;
    }
  }

  // INTRO STEPS
  h2 {
    color: $grey900;
    margin-bottom: 22px;
  }

  .tabsContent {
    width: $introStepsPageWidth;
    margin: 25px auto 100px auto;
    background-color: white;
    border-radius: $border-radius;
    white-space: nowrap;
    overflow: hidden;
    transition: max-height 0.5s ease;
    border: 1px solid $grey100;
    border-radius: 24px;
    @media (max-width: 1280px) {
      width: $introStepsPageWidthSmall;
    }

    .bogie {
      transition: transform 0.5s ease-in-out;
      * {
        white-space: break-spaces;
      }
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      & > div {
        display: flex;
        overflow-x: hidden;
        flex: 0 0 $introStepsPageWidth;
        position: relative;
        padding: 44px;
        min-height: calc(100vh - 350px);
        @media (max-width: 1280px) {
          flex: 0 0 $introStepsPageWidthSmall;
        }
        .welcome {
          .displayFlex {
            margin-top: 40px;
            div {
              gap: 15px;
              padding: 0 20px;
              max-width: 230px;
              height: 200px;
              @media (max-width: 1280px) {
                padding: 20px;
              }
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: 1px solid $grey200;
              margin: 12px;
              border-radius: 8px;
              svg {
                margin: 0 auto 16px auto;
              }
            }
          }
          svg {
            margin: 0 auto 16px auto;
          }
          .error {
            margin-top: 32px;
          }
        }
        .processApp {
          .controls {
            margin: 40px auto 0 auto;
            display: flex;
            width: 640px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid $grey200;
            background: $grey50;
            button {
              border: none;
              text-align: center;
              display: flex;
              padding: 8px 12px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              border-radius: 6px;
              background: transparent;
              color: $grey500;
              &.active {
                background: white;
                color: $grey800;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
                  0px 1px 3px 0px rgba(16, 24, 40, 0.1);
              }
            }
          }
          .displayFlex {
            margin-top: 23px;
            flex-wrap: wrap;
            gap: 16px;
            div {
              flex: 0 0 calc(33.33% - 11px);
              display: flex;
              height: 154px;
              padding: 16px;
              align-items: center;
              gap: 4px;
              flex-shrink: 0;
              border-radius: 8px;
              border: 1px solid $grey200;
              background: white;
              flex-direction: column;
              justify-content: space-between;
              @media (max-width: 1280px) {
                height: 220px;
              }
              span {
                width: 100%;
              }
              .textXlBold,
              .textSmNormal {
                text-align: left;
                width: 100%;
                color: $grey700;
                margin-bottom: 8px;
              }
              button {
                display: flex;
                padding: 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid $primary600;
                background: $primary600;
                color: white;
                width: 100%;
              }
            }
          }
          & > p,
          & > div {
            margin-top: 20px;
          }
          .error {
            .turnOffDisallowedApps::before {
              content: ' ';
              background: url(../assets/error-circle.svg) no-repeat center;
              width: 22px;
              height: 22px;
              display: inline-block;
              background-size: cover;
              margin: 0 10px 0 0;
            }
            ul {
              margin: -4px 24px 12px 24px;
            }
          }
        }

        .shareScreen {
          .displayFlex {
            border-radius: 8px;
            background: $grey100;
            justify-content: center;
            align-items: center;
            padding: 34px;
            margin-top: 16px;
            @media (max-width: 1280px) {
              img {
                width: 630px;
              }
            }
          }
        }
        .idCheckIntro,
        .faceCheckIntro,
        .roomCheckIntro {
          & > div {
            margin: auto;
          }
          & > img {
            width: 594px;
          }
          .images {
            display: flex;
            p {
              flex-basis: 50%;
              margin-bottom: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
              img {
                margin-bottom: 10px;
                width: 160px;
              }
            }
          }
        }
        .idCheck,
        .faceCheck,
        .roomCheck {
          & > div {
            margin: auto;
          }
          .textMdNormal.width682 {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
          }
          .confimModal {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: rgba($grey400, 0.7);
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            & > div {
              background: white;
              text-align: left;
              width: 400px;
              padding: 24px;
              border-radius: 12px;
              box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
                0px 20px 24px -4px rgba(16, 24, 40, 0.08);
              .displayFlex {
                margin-top: 32px;
                gap: 12px;
                justify-content: flex-end;
                button {
                  padding: 14px;
                  height: 36px;
                }
              }
            }
          }
          .snapshot {
            width: $snapshotAreaWidth;
            height: 300px;
            border-radius: 8px;
            overflow: hidden;
            margin: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $grey100 url(../assets/no-cam.svg) no-repeat center
              center;
            border: 1px solid $grey200;
            &.hideIcon {
              background-image: none !important;
            }
            @media (max-width: 1280px) {
              width: $snapshotAreaWidthSmall !important;
            }
            .frame {
              width: 380px;
              height: 230px;
              position: sticky;
              z-index: 1;
              display: flex;
              align-content: space-around;
              flex-direction: column;
              justify-content: space-between;
              white-space: nowrap;
              text-align: center;
              color: white;
              font-weight: 600;
              text-transform: uppercase;
              margin: auto;
              @media (max-width: 1280px) {
                width: 285px;
              }
            }
            .topBounding,
            .bottomBounding {
              display: flex;
              justify-content: space-between;
              div:first-child,
              div:last-child {
                width: 30px;
                height: 30px;
              }
              .text {
                text-align: center;
                overflow: visible;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                font-size: 1.1rem;
                margin-top: -6px;
              }
              .cornerLeftTop {
                transform: scaleX(-1);
              }
            }
            .bottomBounding {
              align-items: flex-end;
              .cornerLeftBottom {
                transform: scale(-1);
              }
              .cornerRightBottom {
                transform: scaleY(-1);
              }
            }
            video,
            img,
            #imageCanvasId {
              position: absolute;
              z-index: 0;
              width: 100%;
              object-fit: fill;
            }
            #imageCanvasId {
              cursor: crosshair;
            }
          }
          .countdown {
            width: 550px;
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(8px);
            display: flex;
            color: rgba(255, 255, 255, 0.5);
            justify-content: space-around;
            height: 50px;
            position: absolute;
            z-index: 11;
            bottom: 0;
            align-items: center;
            font-size: 48px;
            font-weight: 700;
            padding: 40px 0;
            border-radius: 0px 0px 8px 8px;
            @media (max-width: 1280px) {
              width: 400px;
            }
            svg {
              fill: rgba(255, 255, 255, 0.5);
              height: 25px;
            }
            .active {
              color: white;

              svg {
                fill: white;
              }
            }
          }
          .controls {
            margin-top: 20px;
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            width: $snapshotAreaWidth;
            margin-bottom: 40px;
            & > div {
              display: flex;
              button {
                text-align: center;
                display: block;
                flex: 1;
                margin-left: 14px;
                font-size: 14px;
              }
            }
            @media (max-width: 1280px) {
              width: $snapshotAreaWidthSmall !important;
            }
          }
        }
        .faceCheck .frame {
          width: 160px !important;
          height: 265px !important;
        }
        .roomCheck {
          .snapshot {
            width: 550px;
          }

          button.play {
            z-index: 1;
            width: 80px;
            height: 80px;
            border-radius: 100%;
            background: $primary700;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            &.textSmSemibold {
              color: white;
              background: transparent;
              width: auto;
              height: auto;
              border: none;
              gap: 8px;
              &:before {
                content: '';
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                  0deg,
                  rgba(52, 64, 84, 0.7) 0%,
                  rgba(52, 64, 84, 0.7) 100%
                );
              }
            }
          }

          video {
            &.mirrorSnapshot {
              transform: scaleX(-1) !important;
            }
            &::-webkit-media-controls {
              display: none;
            }
            &.playbackWatched {
              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 0, 0, 0.5);
              }
            }
          }

          button.recording {
            display: inline-flex;
            padding: 12px 19px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 10000px;
            border: 1px solid $grey900;
            background: $grey900;
            span {
              border-left: 1px solid $grey500;
              padding-left: 8px;
              line-height: 16px;
            }
          }

          .controls {
            width: 550px;
          }

          .progressNav {
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 55px;
            @media (max-width: 1280px) {
              margin-left: 35px;
            }
            .textSmSemibold {
              color: $primary700;
              margin-top: 12px;
            }
            .check {
              display: flex;
              padding: 0;
              margin: 0 0 24px 0;
              gap: 15px;
              text-align: left;
              &:before {
                content: '';
                display: block;
                width: 2px;
                height: 30px;
                background-color: $grey200;
                position: absolute;
                left: 11px;
                top: 29px;
              }
              &:last-of-type {
                margin: 0;
                &:before {
                  display: none;
                }
              }
              &.passed {
                &:before {
                  background-color: $primary600;
                }
              }
              .label {
                margin: 0;
                padding: 0;
              }
              .name {
                text-align: left;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }
          }
        }
        .idCheck {
          .controls {
            button {
              padding: 10px 15px !important;
              font-size: 14px;
            }
            div {
              gap: 14px;
              button {
                margin: 0 !important;
                flex: auto !important;
              }
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .introCheck {
    line-height: auto;
    margin: 20px auto;
    &.outro {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .warning{
        margin-top: 28px;
      }
    }

    .beside {
      display: flex;
      gap: 50px;
      align-items: center;
      justify-content: space-around;
      & > div {
        flex: 1;
      }
    }

    p.width530.textMdNormal {
      margin-bottom: 24px;
    }

    .video-wrapper {
      width: 432px;
      height: 280px;
      margin: 0 auto 24px auto;
      border-radius: 8px;
      overflow: hidden;

      video {
        object-fit: cover;
        padding: 0;
        margin: 0;
        height: 100%;
      }
    }

    .MuiOutlinedInput-root {
      width: 100%;
      margin: 5px auto;
      .MuiOutlinedInput-notchedOutline {
        border-color: grey;
      }
      .MuiSelect-select {
        font-size: 14px;
        color: $grey900;
        text-align: left;
        white-space: nowrap;
        padding: 4px 40px 8px 20px;
      }
    }

    .volume {
      margin: 24px 0 0 0;
      span {
        margin: 0 3.5px;
      }
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .speed-test-box {
      padding-top: 50px;
      font-size: 14px;
      color: $text-color-dark-blue;
      width: 560px;
      margin: auto;

      .textLgMedium {
        color: $grey900;
        margin-bottom: 8px;
      }

      .textSmNormal {
        color: $grey500;
      }

      button {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .MuiLinearProgress-root {
        background-color: $grey100;
        height: 20px;
        border-radius: 8px;
        margin-bottom: 44px;

        .MuiLinearProgress-bar {
          background-color: $primary500;
        }
      }
    }

    .cam-disabled-icon {
      width: 500px;
      height: 330px;
      background: $grey100;
      border-radius: 8px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .cam-select-preview {
      padding: 10px;
      height: 200px;
    }

    .cam-mic-selection-box {
      width: 432px;
      margin: 32px auto auto auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .input-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px;
        margin: 5px;
        min-width: 250px;
        max-width: 100%;

        span {
          display: flex;
          align-items: center;
          svg {
            margin-right: 5px;
          }
        }

        .MuiInputBase-root,
        .MuiSlider-root {
          width: 360px;
        }
      }
    }
  }

  .reading {
    text-align: justify;
    margin-bottom: 20px;
    border-bottom: 1px solid $grey300;
    position: relative;
    padding: 0 20px 30px 0;
    min-height: 400px;
    color: $grey700;
    display: flex;
    flex-direction: column;
    margin-top: -40px;

    h1,
    h2 {
      text-align: center;
      color: $grey900;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
      margin: 25px 0 15px 0;
    }
    h3 {
      font-size: 22px;
      text-align: center;
      margin: 20px 0 10px 0;
      color: $grey900;
    }
    h4 {
      font-size: 18px;
      text-align: center;
      margin: 15px 0 10px 0;
      color: $grey900;
    }
    h5 {
      font-size: 16px;
      text-align: center;
      margin: 13px 0 8px 0;
      color: $grey900;
    }
    p {
      margin-bottom: 15px;
    }
    ul {
      list-style-type: none;
      li {
        &:before {
          content: '';
          width: 3px;
          height: 3px;
          background-color: $grey700;
          border-radius: 50%;
          position: absolute;
          margin: 10px 0 0 -14px;
        }
      }
    }
  }

  .rulesCheckboxContainer {
    display: flex;
    flex-direction: column;
  }

  .info,
  .error,
  .warning {
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    text-align: left;
    &.withIcon {
      display: flex;
      gap: 10px;
      svg {
        width: 36px;
      }
    }
  }

  .info {
    color: $primary700;
    background: $primary25;
    border: 1px solid $primary300;
  }

  .error {
    color: $error700;
    border: 1px solid $error300;
    background: $error25;
    ul {
      li {
        white-space: normal !important;
        color: $grey900;
        left: -20px;
        position: relative;
        &::marker {
          text-indent: 100px !important;
        }
      }
    }
  }

  .warning {
    color: $warning700;
    border: 1px solid $warning300;
    background: $warning25;
  }

  .checksTimer {
    display: flex;
    position: absolute;
    top: 18px;
    z-index: 1000;
    left: -310px;
    transform: translate(100%, 0);
    gap: 7px;
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    border-radius: 1rem;
    background-color: #eaecf0;
    width: auto;
    font-size: 15px;
    align-items: center;
    svg {
      font-size: 18px;
    }
    &.error {
      background-color: $error100;
    }
  }

  .checksBounding {
    width: $introStepsPageWidth;
    margin: 10px auto;
    position: relative;
    padding-bottom: 30px;
    @media (max-width: 1280px) {
      width: $introStepsPageWidthSmall;
    }

    .progressNav {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-size: 0.825rem;
      list-style: none;
      margin-left: 0;
      padding-inline-start: 0;

      .check {
        padding: 0 10px;
        width: 100%;
        position: relative;

        &:first-child {
          .indicator {
            background-color: transparent !important;
          }

          &.init-check {
            .indicator {
              &:after {
                display: block !important;
              }
            }
          }
        }

        .name {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 40px;
          color: $grey700;
          font-weight: bold;
          @media (max-width: 1280px) {
            padding-top: 17px;
            line-height: 20px;
          }
        }

        .label {
          width: 24px;
          height: 24px;
          margin: auto;
          color: white;
          border: 8px solid $grey300;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 10px;
        }

        .indicator {
          position: absolute;
          display: block;
          width: 84%;
          height: 2px;
          left: -42%;
          bottom: 50px;
          background-color: $progressBaseline;

          @media (max-width: 1280px) {
            width: 80%;
            left: -40%;
          }
        }

        &.passed {
          .indicator {
            background-color: $progressIndicator;
          }
          .label {
            border: 1px solid $primary600;
            background-color: $primary600;
          }
        }

        &.current {
          .name {
            color: $primary700;
          }

          .label {
            border-color: $primary600;
          }

          .indicator {
            background-color: $progressIndicator;
            & .subcheck-indicator {
              content: '';
              display: block !important;
              left: 119%;
              position: absolute;
              background-color: $progressIndicator;
              height: 2px;
              z-index: 10;
              @media (max-width: 1280px) {
                left: 125%;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding: 14px 32px;
    background: #ffffff;
    box-shadow: 0px -12px 16px -4px rgba(16, 24, 40, 0.08),
      0px -4px 6px -2px rgba(16, 24, 40, 0.03);
    #scrollMore {
      grid-column: 2;
      justify-self: center;
      color: $grey700;
      position: relative;
      top: -48px;
      cursor: default;

      .test-continues-polygon {
        position: absolute;
        top: 149px;
        left: -628px;
        margin: 5rem auto;
        width: 80rem;
        height: 80rem;
        background-color: white;
        border-radius: 20rem 1rem 300rem 1rem;
        transform: rotate(45deg);
        box-shadow: 0px -4px 6px -2px rgba(16, 24, 40, 0.03),
          0px -12px 16px -4px rgba(16, 24, 40, 0.08);
      }
      .test-continues-overlay {
        position: absolute;
        top: 27px;
        left: -129px;
        background-color: white;
        width: 300px;
        height: 70px;
        z-index: 402;
      }
      .arrow-down-toggle {
        position: absolute;
        margin-top: 0;
        display: inline-block;
        padding: 4px;
        border-radius: 50%;
        z-index: 403;
        left: -4px;
        cursor: pointer;
        background-color: #f8f8fa;

        .arrow-down-active {
          display: none;
        }

        &:hover .arrow-down {
          display: none;
        }

        &:hover .arrow-down-active {
          display: inline-block;
        }
      }

      &:focus,
      &:active {
        border: none;
        box-shadow: none;
      }
    }
    .next {
      border-radius: $border-radius;
      position: absolute;
      right: 50px;
      font-weight: bold;
      svg {
        animation: spin 1s linear infinite;
      }
      &.bgActive svg {
        margin-left: 10px;
        animation: none;
      }
      &.bgDisabled {
        span svg {
          animation: none;
          margin-left: 10px;
          path {
            stroke: $grey400 !important;
          }
        }
      }
    }
    span.textMdMedium {
      display: inline-flex;
      align-items: center;
      padding: 4px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 16px;
      background: $grey50;
      mix-blend-mode: multiply;
      color: $grey700;
    }
  }
}
