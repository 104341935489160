.component-button {
    display: inline-flex;
    width: 25%;
    flex: 1 0 auto;
}

.component-button.wide {
    width: 50%;
}

.component-button button {
    background-color: #ebecec;
    color: #222;
    /*border: 1px solid transparent;*/
    font-size: 30px;
    /*margin: 0 1px 0 0;*/
    /*flex: 1 0 auto;*/
    padding: 0;
    /*cursor: pointer;*/
    border-radius: 3px;
    display: block;
    text-align: center;
    padding: 5px !important;
}

.component-button button:focus {
    border: 1px solid #ccc;
    outline: 0;
}

.component-button button:hover {
    background-color: #ccc;
    outline: 0;
}

.component-button.extended {
    display: none;
}

.component-button:last-child button {
    margin-right: 0;
}

.component-button.orange button {
    background-color: #0078bf;
    color: white;
}

    .component-button.orange button:hover {
        background-color: #0086d6;
    }

.component-button.grey button {
    background-color: #d6d6d6;
    color: #222;
}

.component-button.grey button:hover {
    background-color: #aaa;
}

.component-button.disabled button {
    color: #7f7f7f;
    cursor: default;
}

.component-button-panel.extended .component-button.extended {
    display: inline-flex;
}

.component-button-panel.extended .component-button {
    width: 17.5%;
}

.component-button-panel.extended .component-button.small {
    width: 10%;
}

.component-button-panel.extended .component-button.wide {
    width: 35%;
}

.component-button-panel.extended .component-button.extended.wide {
    width: 20%;
}

.component-button-panel.extended .component-button.small button {
    font-size: 20px;
}

.component-button.undo {
    position: absolute;
    display: inline-block;
    top: -55px;
    left: 10px;
    
}

    .component-button.undo button {
        font-size: 36px;
        background-color: transparent;
        color: #777;
        opacity: 0.5;
    }

