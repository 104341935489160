﻿.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(52, 64, 84, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.2s 0.2s forwards;

  .react-confirm-alert-body {
    width: 400px;
    padding: $px-24;
    text-align: left;
    background: $white;
    border-radius: $px-12;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);

    .alert-title {
      font-size: $px-18;
      color: $gray-900;
      margin-bottom: $px-8;
    }

    .alert-text {
      margin-bottom: $px-32;
      font-size: $px-14;
      line-height: $px-20;
      color: $gray-500;
    }

    .react-confirm-alert-button-group {
      display: flex;
      gap: $px-8;

      button {
        flex: 1;
      }
    }
  }
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
