$gray-25: #fcfcfd;
$gray-50: #f9fafb;
$gray-100: #f2f4f7;
$gray-200: #eaecf0;
$gray-300: #d0d5dd;
$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$gray-900: #101828;

$primary-50: #f0f9ff;
$primary-100: #e0f2fe;
$primary-300: #7cd4fd;
$primary-500: #0ba5ec;
$primary-600: #0086c9;
$primary-700: #026aa2;

$error-50: #fef3f2;
$error-100: #fee4e2;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;

$warning-25: #fffcf5;
$warning-50: #fffaeb;
$warning-100: #fef0c7;
$warning-300: #fec84b;
$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;

$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-500: #12b76a;
$success-600: #039855;
$success-700: #027a48;

$blue-dark-50: #eff4ff;
$blue-dark-100: #d1e0ff;
$blue-dark-500: #2970ff;
$blue-dark-600: #155eef;
$blue-dark-700: #004eeb;

$purple-50: #f4f3ff;
$purple-100: #ebe9fe;
$purple-500: #7a5af8;
$purple-600: #6938ef;
$purple-700: #5925dc;

$fuchsia-50: #fdf4ff;
$fuchsia-100: #fbe8ff;
$fuchsia-500: #d444f1;
$fuchsia-600: #ba24d5;
$fuchsia-700: #9f1ab1;

$rose-50: #fff1f3;
$rose-100: #ffe4e8;
$rose-500: #f63d68;
$rose-600: #e31b54;
$rose-700: #c01048;

$yellow-50: #fefbe8;
$yellow-100: #fef7c3;
$yellow-500: #eaaa08;
$yellow-600: #ca8504;
$yellow-700: #a15c07;

$white: #ffffff;
$black: #000000;
$primary: $primary-600;
$secondary: $gray-500;
