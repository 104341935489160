﻿.item-content {
  .filling-item {
    .filling-item-select {
      padding: 8px 42px 8px 14px;
      min-width: 167px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      vertical-align: middle;
      background-color: $white;
      border: 1px solid $gray-300;
      border-radius: 8px;
      appearance: none;
      @include base64image('chevron-down');
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
    }

    input[type='radio'] {
      top: 5px;
      left: 4px;
      position: absolute;
      margin: 5px 5px 0px 5px;
      visibility: hidden;

      & + label {
        cursor: pointer;
      }
    }

    .itemOptionKey {
      top: 0;
      left: 0;
      width: $item-icon-size;
      height: $item-icon-size;
      display: inline-block;
      border-radius: 50%;
      border: $item-icon-border;
      font-size: 16px;
      text-align: center;
      line-height: 30px;
      color: $gray-400;
      padding-left: 1px;
      z-index: 100;
    }

    .clearAnswer {
      position: absolute;
      width: calc(100% - $px-10);
      height: calc(100% - $px-10);
      color: $white;
      cursor: pointer;

      &::before {
        content: attr(value);
        width: $item-icon-size;
        height: $item-icon-size;
        position: absolute;
        top: 0;
        background-color: $primary-500;
        border-radius: 50%;
        border: 1px solid $primary-500;
        font-size: $px-16;
        z-index: 101;
        color: $white;
        text-align: center;
        line-height: 30px;
        font-weight: bold;
      }

      &:after {
        @include x-close('center', 5);
      }

      &:hover {
        background-position: 0 -10px;
      }
    }

    .option-box {
      position: relative;
      margin-top: $px-8;
      padding: $px-5;
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: $px-16;
      }
    }
  }

  &.hide-distractor-keys {
    .filling-item {
      .option-box {
        .clearAnswer {
          &::before {
            content: '';
            border: 9px $primary-600 solid;
            background-color: $white;
          }
        }

        label {
          .itemOptionKey {
            font-size: 0;
            color: transparent;
            border: 1px $gray-400 solid;
          }
        }
      }
    }
  }
}
