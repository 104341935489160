﻿#sidebar {
  position: fixed;
  top: $buttons-row-height;
  right: $px-26;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // avoid overlap continue button
  height: calc(100vh - 2 * $buttons-row-height);
  z-index: 403;

  // local variable to help with buttons
  $button-size: $px-56;

  // if there are more than one button, align them to the top
  &:has(button:nth-last-of-type(n + 2)) {
    top: calc($header-min-height + $buttons-row-height);
    justify-content: start;
    height: calc(100vh - 3 * $buttons-row-height);
  }

  .sidebar-button {
    position: relative;
    margin: 0 0 $px-64 0;
    padding: 0;
    display: grid;
    justify-items: center;
    justify-content: center;
    grid-template-rows: 3.5rem 1fr;
    width: $button-size;
    height: $button-size;
    background-color: $white;
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 2px 4px -2px #1018280f;
    box-shadow: 0px 4px 8px -2px #1018281a;

    .sidebar-label {
      padding-top: $px-8;
      font-size: $px-14;
      font-weight: 600;
    }

    &.note {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        background-color: $warning-500;
        border-radius: 50%;
      }
    }

    &.active {
      background-color: $primary-50;

      .sidebar-label {
        color: $primary-700;
      }
    }
  }

  .calculator-component {
    max-width: 500px;
    max-height: 90%;

    .component-app {
      .component-display {
        background-color: $gray-200;

        .component-display-result {
          padding: $px-10 $px-18;
          font-size: $px-48;
          line-height: $px-60;
          color: $gray-700;
        }
      }

      .component-button-panel {
        .component-button {
          button {
            padding: $px-10 $px-18 !important;
            font-size: $px-24;
            line-height: $px-32;
            color: $gray-700;
            font-weight: 500;
            background-color: $gray-200;
          }

          &.grey {
            button {
              background-color: $gray-300;
            }
          }

          &.orange {
            button {
              color: $white;
              background-color: $primary-600;
            }
          }
        }

        &.extended {
          .component-button {
            button {
              padding: $px-5 !important;
            }
          }
        }
      }
    }
  }

  .support-materials-modal {
    max-width: none;
    min-width: 600px;
    overflow: hidden;

    .scrollable {
      overflow: auto;
      max-height: 80vh;

      @include scrollbar();
    }

    .supportMaterials-tab {
      cursor: pointer;

      .supportMaterials-header {
        font-size: $px-18;

        .supportMaterials-arrow {
          margin-right: $px-10;
          transition-duration: 0.2s;
          transition-property: transform;
        }

        &.opened {
          .supportMaterials-arrow {
            transform: rotate(90deg);
          }
        }
      }

      .supportMaterials-content {
        display: inline-block;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .notepad-modal {
    padding-bottom: $px-25;
    min-width: 400px;

    textarea {
      padding: $px-10 $px-14;
      margin-bottom: $px-20;
      width: 100%;
      max-width: none;
      border-radius: $px-8;
      border-color: $gray-300;
    }
  }

  @include breakpoint('small') {
    @include sidebar-position('small', $button-size);
  }

  @include breakpoint('normal') {
    @include sidebar-position('normal', $button-size);
  }

  @include breakpoint('large') {
    @include sidebar-position('large', $button-size);
  }

  @include breakpoint('medium-height') {
    .sidebar-button {
      margin-bottom: $px-32;
      width: $px-32;
      height: $px-32;
      grid-template-rows: 2rem 1fr;

      img {
        width: $px-16;
      }

      .sidebar-label {
        padding-top: 0;
        font-size: $px-12;
      }
    }
  }

  @include breakpoint('small-height') {
    .sidebar-button {
      margin-bottom: $px-24;
      width: $px-24;
      height: $px-24;
      grid-template-rows: 1.5rem 1fr;

      .sidebar-label {
        font-size: $px-10;
      }
    }
  }
}
