@font-face {
  font-family: 'Public Sans';
  src: url('../../fonts/PublicSans-VariableFont_wght.woff2') format('woff2');
  font-weight: 100 900;
  font-stretch: 50% 200%;
}

body {
  background-color: $page-background-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $gray-700;

  * {
    font-family: 'Public Sans', 'Open Sans', sans-serif;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
}

img,
svg {
  vertical-align: middle;
}

button {
  background-color: transparent;
  border-radius: 8px;
  border: none;
  padding: $px-10 $px-25;
  cursor: pointer;
  outline: none;
  color: $gray-700;
  display: inline-flex;
  align-items: center;
  font-size: $px-16;
  font-weight: 600;
  line-height: $px-24;
  border: 1px solid $gray-300;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  margin: 0;
  max-width: 280px;
}

p {
  margin: 0;
  position: relative;
}

// spacing
.mr8 {
  margin-right: $px-8;
}
.ml8 {
  margin-left: $px-8;
}

section.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 9999;

  &.hidden {
    display: none;
  }

  .loader-icon {
    margin-bottom: $px-16;
    width: 58px;
    height: 58px;
    animation: $spin-linear;
  }
}

.box {
  position: relative;
  padding: $px-20;
  border-radius: $px-5;

  p {
    margin-bottom: 0.5rem;
  }
}

.infobox {
  background-color: $gray-200;
}

.half-width {
  width: 50%;
}

.test-welcome {
  min-height: 85px;
}

.right-bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.warning-700 {
  color: $warning-700;
}

@media print {
  body {
    display: none;
  }
}
