﻿// Bootstrap class leftovers
.container {
  width: 100%;
  padding-right: $px-15;
  padding-left: $px-15;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint('small') {
    max-width: 800px;
  }

  @include breakpoint('normal') {
    max-width: 960px;
  }

  @include breakpoint('large') {
    max-width: 1140px;
  }
}

.btn {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  border: 1px solid transparent;
}

.btn-primary {
  background-color: $primary-600;
  border-color: $primary-600;
  border-radius: 8px;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    border-color: $primary-700;
    background-color: $primary-700;
  }

  &:disabled {
    cursor: not-allowed;
    color: $gray-400;
    border-color: $gray-100;
    background-color: $gray-100;
  }
}

.btn-secondary {
  color: $gray-700;
  border: 1px solid $gray-300;
  border-radius: 8px;
  background-color: $white;

  &:hover {
    color: $gray-800;
    border-color: $gray-50;
    background-color: $gray-50;
  }

  &:focus,
  &:active {
    color: $gray-700 !important;
    border-color: $gray-300 !important;
    background-color: $white !important;
  }

  &:disabled {
    cursor: not-allowed;
    color: $gray-300;
    border-color: $gray-300;
    background-color: $gray-300;
  }
}
