﻿.keyboard {
  position: relative;
  max-width: 600px;
  margin-bottom: $px-8;

  .keyboard-instructions {
    position: absolute;
    top: $px-8;
    padding-left: $px-8;
    font-size: $px-15;
    line-height: $px-20;
    z-index: 1;
  }

  .react-simple-keyboard {
    position: relative;
    padding-top: $px-60;
    background-color: $white;

    .hg-candidate-box {
      max-width: none;
      width: 100%;
      border: 0;
      border-radius: 0;
      background: $white;
      z-index: 2;

      .hg-candidate-box-list {
        flex-grow: 0;
        color: $gray-800;
        font-size: $px-14;
        font-weight: 600;
        border: 1px solid $gray-400;
        background-color: $gray-100;

        .hg-candidate-box-list-item:hover {
          background: $gray-300;
        }

        .hg-candidate-box-list-item:active {
          background: $gray-400;
        }
      }

      .hg-candidate-box-prev,
      .hg-candidate-box-next {
        display: none;
      }
    }

    .hg-button {
      color: $gray-800;
      font-size: $px-14;
      font-weight: 600;
      border: 1px solid $gray-300;
      border-radius: 0;
      background-color: $gray-100;
      box-shadow: none;

      // caps lock
      &.hg-button-lock {
        flex-grow: 0;
        width: 90px;
      }
    }
  }
}
