.item {
  margin-bottom: $px-16;
  padding: $px-24;
  border-radius: $px-24;
  background-color: $white;
  page-break-inside: avoid;

  &:hover {
    background-color: $white;
  }

  .ItemImage {
    max-width: 100%;
  }

  .floatImageLeft {
    margin-right: 10px;
  }

  .floatImageRight {
    margin-left: 10px;
  }

  .item-number {
    margin-bottom: $px-16;
    display: flex;
    align-items: center;
    font-size: $px-20;
    font-weight: 600;
    line-height: $px-30;
    color: $gray-900;

    .item-index {
      margin: 0;
      font-size: $px-20;
      font-weight: 600;
      line-height: $px-30;
      color: $gray-900;
    }

    .item-theme {
      margin-left: $px-8;
      padding: $px-2 $px-8;
      align-items: center;
      border-radius: $px-16;
      background: $primary-50;
      color: $primary-700;
      font-size: $px-12;
      font-weight: 500;
    }

    .item-number-right-align {
      display: flex;
      margin-left: auto;
      
      .item-score {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        padding: $px-4 $px-12;
        align-items: center;
        gap: $px-8;
        border-radius: $px-16;
        background: $gray-50;
        mix-blend-mode: multiply;
  
        .item-score-partial {
          display: inline-flex;
          gap: $px-5;
          color: $gray-700;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1.125rem;

          .item-score-val {
            font-weight: 600;
          }
        }

        .item-score-separator {
          font-weight: 200;
        }
      }
    }
  }

  .rich-content {
    overflow-x: visible;
    overflow-y: visible;

    .red {
      background: none;
      color: $color-magenta;
      color: $rose-600;
      font-weight: bold;
    }

    .term {
      font-style: italic;
    }

    .mathTerm {
      font-family: 'Times New Roman', Times, serif;
      font-style: italic;
      font-weight: bold;
      font-size: 120%;
    }

    .chemTerm {
      font-family: 'Times New Roman', Times, serif;
      font-size: 120%;
    }

    .biblioCite {
      font-size: xx-small;
    }

    .boldtext {
      font-weight: bold;
    }

    .italictext {
      font-style: italic;
    }

    .underlinetext {
      text-decoration: underline;
    }

    .term {
      font-style: normal;
    }

    .unbold {
      font-weight: normal !important;
    }

    em {
      font-weight: bold;
      font-style: normal;
    }

    .underscore {
      letter-spacing: -3px;
    }

    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      border-collapse: collapse;

      &.compareWrapper {
        display: table;
        width: auto;
      }
    }

    table td,
    table th {
      border: 1px solid black;
    }

    table.noBorder td,
    table.noBorder th {
      border: none;
    }

    table.borderedTable {
      border-style: solid;
      border-color: black;
      border-width: 0 !important; // override inline styling
    }

    table th,
    table td {
      padding: $px-5;
    }

    table.compareWrapper {
      width: 100%;
      min-height: 20px;
      margin: 0;
      padding: 0;
      border: none !important;
      table-layout: fixed;
    }

    table.compareWrapper td {
      width: 45%;
      height: 100%;
      border: 2px solid #cccccc !important;
      word-wrap: break-word;
    }

    table.compareWrapper td.blankTD {
      width: auto;
      border: none !important;
    }
  }

  .answerable-item {
    position: relative;

    .assignment {
      & > p {
        margin-bottom: $px-8;
      }
    }

    .assignment,
    .item-instruction {
      margin-bottom: $px-24;

      & > p {
        textarea {
          max-width: 80%;
          vertical-align: text-top;
        }
      }
    }

    .filling-instruction {
      font-style: italic;
    }
  }

  &.implicit-instruction {
    display: flex;
    flex-wrap: wrap;
    padding: $px-12 $px-16;
    background: $primary-50;
    border: $px-1 solid $primary-300;
    box-sizing: border-box;
    border-radius: $px-16;
    page-break-after: avoid;

    .item-number {
      margin-bottom: 0;
      color: $primary-700;
      font-size: $px-14;
    }

    .toggle-button {
      margin-left: auto;
      padding: 0;
      border: 0;
    }

    .text-item-wrapper {
      width: 100%;

      .text-item {
        margin-top: $px-16;
        color: $primary-700;

        &:first-child {
          margin-top: $px-8;
        }
      }
    }
  }

  &.shared-item {
    position: relative;

    p {
      margin-bottom: $px-8;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    cursor: pointer;
  }

  input[type='text'] {
    border-radius: 8px;
    border-width: 1px;
    font-size: $px-14;
  }

  .item-loader {
    cursor: wait;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    border: solid 2px $primary-600;
    border-radius: 50%;
    border-right-color: $gray-200;
    border-bottom-color: $gray-200;
    border-left-color: $gray-200;
    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
