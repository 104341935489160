$-px-54: -3.375rem;
$-px-4: -0.25rem;
$-px-5: -0.313rem;
$-px-2: -0.125rem;
$-px-1: -0.063rem;
$px-1: 0.063rem;
$px-2: 0.125rem;
$px-3: 0.188rem;
$px-4: 0.25rem;
$px-5: 0.313rem;
$px-6: 0.375rem;
$px-7: 0.438rem;
$px-8: 0.5rem;
$px-9: 0.563rem;
$px-10: 0.625rem;
$px-12: 0.75rem;
$px-13: 0.813rem;
$px-14: 0.875rem;
$px-15: 0.938rem;
$px-16: 1rem;
$px-17: 1.063rem;
$px-18: 1.125rem;
$px-20: 1.25rem;
$px-22: 1.375rem;
$px-24: 1.5rem;
$px-25: 1.563rem;
$px-26: 1.625rem;
$px-28: 1.75rem;
$px-30: 1.875rem;
$px-32: 2rem;
$px-36: 2.25rem;
$px-37: 2.375rem;
$px-38: 2.375rem;
$px-40: 2.5rem;
$px-42: 2.625rem;
$px-44: 2.75rem;
$px-46: 2.875rem;
$px-48: 3rem;
$px-50: 3.125rem;
$px-52: 3.25rem;
$px-54: 3.375rem;
$px-56: 3.5rem;
$px-60: 3.75rem;
$px-61: 3.813rem;
$px-64: 4rem;
$px-67: 4.188rem;
$px-68: 4.25rem;
$px-70: 4.375rem;
$px-72: 4.5rem;
$px-80: 5rem;
$px-84: 5.25rem;
$px-85: 5.313rem;
$px-96: 6rem;
$px-100: 6.25rem;
$px-112: 7rem;
$px-120: 7.5rem;
$px-128: 8rem;
$px-138: 8.625rem;
$px-140: 8.75rem;
$px-160: 10rem;
$px-178: 11.125rem;
$px-230: 14.375rem;
$px-260: 16.25rem;
$px-280: 17.5rem;
$px-360: 22.5rem;
$px-410: 25.625rem;
$px-480: 30rem;
$px-510: 31.875rem;
$px-538: 33.625rem;
$px-550: 34.375rem;
$px-600: 37.5rem;
$px-680: 42.5rem;
$px-800: 50rem;
$px-856: 53.5rem;
$px-900: 56.25rem;
$px-960: 60rem;
$px-1024: 64rem;
$px-1140: 71.25rem;
$px-1440: 90rem;
