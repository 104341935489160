﻿.welcome-panel {
  margin-bottom: $px-120;
  padding: $px-32;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $gray-900;
  background-color: $white;
  border: 1px $gray-100 solid;
  border-radius: $px-24;

  .instruction-counter {
    margin-bottom: $px-32;
    padding: $px-4 $px-12;
    display: flex;
    align-self: center;
    font-weight: 600;
    border-radius: $px-16;
    background-color: $gray-200;

    .timer-message {
      padding: 0 $px-4 0 $px-8;
    }

    .timer-time {
      display: flex;
      font-weight: 700;

      .hide-time,
      .show-time {
        display: flex;
        cursor: pointer;

        img {
          padding-left: $px-8;
        }
      }
    }
  }

  .test-name {
    font-size: $px-24;
    line-height: $px-32;
    font-weight: 600;
    margin-bottom: $px-30;
  }

  .welcome-table {
    display: flex;
    justify-content: space-between;
    padding: $px-10 $px-24;
    width: 100%;
    border-top: 1px $gray-200 solid;

    &:nth-child(1 of .welcome-table) {
      border-top: none;
    }

    .text {
      color: $gray-600;
    }

    .number {
      color: $gray-800;
      font-weight: 600;
    }
  }

  .welcome-instructions {
    margin-top: $px-30;

    & > h4 {
      margin-bottom: $px-26;
      font-size: $px-20;
      font-weight: 600;
      line-height: $px-30;
    }

    .text-item-wrapper {
      ul {
        margin: 0;
        padding-left: $px-16;
        color: $gray-600;
      }
    }
  }
}
