﻿#timer-snackbar,
#highlighter-snackbar {
  & > .notistack-SnackbarContainer {
    max-width: none;
    bottom: calc($buttons-row-height + $px-18);

    .notistack-Snackbar {
      min-width: auto;

      .timer-snackbar-custom,
      .highlighter-snackbar-custom {
        padding: $px-12 $px-16 $px-12 $px-24;
        min-width: auto;
        background-color: $gray-800;
        border-radius: $px-8;

        > div {
          margin: 0;
          padding: 0;
        }

        #notistack-snackbar {
          margin-right: $px-20;
        }

        .timer-snackbar-close,
        .highlighter-snackbar-close {
          padding: 0;
          border: 0;
        }
      }

      .highlighter-snackbar-custom {
        background-color: $gray-900;
        border-radius: $px-24;
      }
    }
  }
}
