﻿@mixin scrollbar() {
  // scrollbar
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-200;
    border: 1px $gray-300 solid;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $gray-300;
  }
}

/**
 * Responsive breakpoint manager - shamelessly modified from sass-guidelin.es
 * @param {String} $breakpoint
 * @requires $breakpoints and $breakpointsRange
 */
@mixin breakpoint($breakpoint, $range: false) {
  $raw-query: map-get(
    if($range == 'range', $breakpointsRange, $breakpoints),
    $breakpoint
  );

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/**
 * Navigation styling
 * @param {String} $mega - in case there are more than 75 questions, default false
 */
@mixin navigationStyling($mega: false) {
  /*
  Rounded borders on numbered navigation
  Numbers break at every 20th item at client window size at min. resolution 992px,
  thus nth-child(20n), the nth-child(20n + 1) is for the 1st element on the next line

  breakpoint range small (992px to 1199px) - 20 items on a row
  breakpoint range normal (1200px to 1399px) - breaks at number 25
  breakpoint large (1340px and up) - breaks at number 30
  */
  $default: (
    'small': 20,
    'normal': 25,
    'large': 30,
  );

  $megaValues: (
    'small': 34,
    'normal': 42,
    'large': 50,
  );

  $map: if($mega, $megaValues, $default);

  @each $key, $value in $map {
    @include breakpoint($key, if($key == 'large', false, 'range')) {
      &:nth-child(#{$value}n) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:nth-child(#{$value}n + 1) {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border: 1px solid $gray-200;
      }
    }
  }
}

/**
 * Calculate toolbar position
 * @param {string} $container-size
 * @param {number} $button-width
 * @requires $container-widths
 * calculation = (middle of the screen - (container max width / 2 + button width / 2 + margin 2rem))
 */
@mixin sidebar-position($container-size, $button-width) {
  $container-width: map-get($container-widths, $container-size);

  @if $container-width {
    right: calc(
      50% - (#{$container-width} / 2 + #{$button-width} / 2 + #{$px-32})
    );
  } @else {
    @warn "Container width for size `#{$container-size}` not found!";
  }
}

/**
 * Show icon to deselect an answer
 * @param {string} $top - center, default: auto
 * @param {number} $left - number of pixels to move, default: 0
 */
@mixin x-close($top: auto, $left: 0) {
  $size: 20px;

  content: '';
  position: absolute;
  left: calc((#{$size} + #{$left + 'px'}) * -1);
  width: $size;
  height: $size;
  @include base64image('x-close-gray700');
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  @if $top == 'center' {
    top: calc(50% - $size / 2);
  } @else {
    top: $top;
  }
}

@mixin base64image($image) {
  $base64: map-get($base64images, $image);

  background-image: url('data:image/svg+xml;base64,#{$base64}');
}
