﻿nav {
  min-height: $header-min-height;
  color: $gray-700;
  background-color: $page-background-color;

  &.navbar {
    position: sticky;
    top: 0;
    padding: 0.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    z-index: 403;
  }

  .logo {
    display: flex;
    flex: 1;

    .header-timer {
      margin-left: $px-24;
      display: flex;
      align-items: center;

      .counter {
        display: flex;
        padding: $px-4 $px-12;
        font-weight: 600;
        border-radius: $px-16;
        background-color: $gray-200;

        .timer-message {
          padding: 0 $px-4 0 $px-8;
        }

        .timer-time {
          display: flex;
          font-weight: 700;

          .hide-time,
          .show-time {
            display: flex;
            cursor: pointer;

            img {
              padding-left: $px-8;
            }
          }
        }
      }

      #screen-readers-alert {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
    }
  }

  .title {
    margin: 0;
    padding: $px-4 $px-12;
    font-size: $px-16;
    font-weight: 600;
    line-height: $px-24;
    border-radius: $px-16;
    background-color: $gray-200;

    &:focus {
      outline: 1px solid transparent;
    }
  }

  .icon-box {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .initials {
      width: 32px;
      height: 32px;
      font-size: $px-14;
      font-weight: bold;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
      color: $primary-50;
      border-radius: 50%;
      background: $primary-500;
    }
  }
}
