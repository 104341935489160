﻿.icon-box {
  padding-right: 0;
}

.header-icon {
  display: inline-block;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.modal-button-row {
  display: flex;
  justify-content: flex-end;
  gap: $px-12;
}

.btn-close {
  margin: 0;
  padding: 0;
  border-color: transparent;
  border-radius: $px-8;
  border-width: 0;
}

.sidebar-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-bottom: $px-24;
  min-width: 600px;
  max-width: 100%;
  z-index: 999;
  background-color: $white;
  border: 1px $gray-200 solid;
  border-radius: $px-12;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  &.top-modal {
    z-index: 1000;
  }

  .modal-sidebar-draggable {
    margin-bottom: $px-20;
    padding: $px-24 $px-24 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;

    .modal-title {
      font-size: $px-18;
      font-weight: 500;
    }
  }

  .sidebar-modal-content {
    padding: 0 $px-24;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  background: rgba(52, 64, 84, 0.7);

  .support-modal {
    position: relative;
    padding: $px-40;
    max-width: $px-1024;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $px-12;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);

    .modal-sidebar-header {
      margin-bottom: $px-20;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .modal-title {
        font-size: $px-18;
        font-weight: 500;
      }
    }

    .support-modal-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      p {
        margin-bottom: $px-40;
        width: 100%;
      }

      .contact-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $px-20;

        .box-title {
          font-size: $px-20;
          line-height: $px-30;
          font-weight: 500;
          color: $gray-900;
        }

        .subtitle {
          color: $gray-500;
        }

        .text {
          color: $primary-700;
        }
      }
    }
  }
}

.instruction-box {
  display: inline-block;
  margin-top: $px-10;
  margin-right: $px-18;
  margin-bottom: $px-10;

  .instruction-modal {
    top: 130px;
    max-width: 700px;

    .scrollable {
      overflow: auto;
      max-height: calc(100vh - 200px);

      @include scrollbar();
    }
  }

  .instruction-icon {
    display: inline-block;
    position: relative;
    top: 1px;
    padding: 0;
    height: 28px;
    width: 28px;
    @include base64image('instruction');
    background-repeat: no-repeat;
    border: 0;
  }
}
