﻿.open-item {
  textarea {
    padding: $px-8 $px-12;
    border: 1px solid $gray-300;
    box-sizing: border-box;
    border-radius: 8px;
    width: calc(100% - 20px);
    min-width: 450px;
    max-width: 100% !important;
  }

  .options {
    border: 0px solid black;
  }

  .open-item-instruction {
    background-color: #f0eeee;
    border-radius: 5px;
    color: #444;
    font-size: $px-13;
    margin-bottom: $px-3;
    padding: $px-10 $px-15;
  }

  input {
    padding: $px-8 $px-12;
    min-width: 35px;
    width: 320px;
    height: 38px;
    line-height: 20px;
    border: 1px solid $gray-300;
    box-sizing: border-box;

    &[usekeyboard='true'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .keyboard-button {
    margin-left: -1px;
    vertical-align: bottom;
    height: 38px;
    line-height: 20px;
    border: 1px solid $gray-300;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @include base64image('keyboard');
    background-repeat: no-repeat;
    background-position: center;

    &.keyboard-textarea {
      float: right;
      margin-right: 20px;
      border-radius: 8px;
    }

    &:hover {
      background-color: $gray-300;
    }

    &:focus {
      border-width: 1px !important;
    }
  }
}
