﻿#TestApp {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;

  @include scrollbar();

  #navigation-row {
    display: flex;
    position: sticky;
    top: 70px;
    z-index: 402;
    background: $page-background-color;

    &.forward-stepper {
      align-items: center;

      .progress {
        width: 100%;
        height: 2px;
        border-radius: 0;
        overflow: visible;
        background-color: $gray-300;

        .progress-bar {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: visible;
          background-color: $primary-600;

          .progress-thumb {
            position: absolute;
            right: 0;
            width: 24px;
            height: 24px;
            border-radius: 100vw;
            background-color: $primary-600;
          }

          .item-counter {
            position: absolute;
            top: -28px;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 16px;
            font-size: $px-12;
            font-weight: bold;
            color: $primary-600;

            .grey-font {
              color: $gray-600;
              white-space: pre;
            }
          }
        }
      }
    }

    #item-links {
      display: flex;
      flex-wrap: wrap;
      margin-top: $px-10;
      margin-bottom: $px-10;
      border: 0px solid black;
    }

    .item-link {
      display: flex;
      justify-content: center;
      margin-bottom: $px-5;
      padding: 0;
      width: 35px;
      height: 28px;
      font-size: $px-14;
      font-weight: bold;
      color: $gray-700;
      border: 1px solid $gray-200;
      border-left: none;
      border-radius: 0;
      background-color: $white;

      &:first-child {
        border-left: 1px solid $gray-200;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        &.flagged {
          &:after {
            border-top-right-radius: 6px;
          }
        }
      }

      &.OK {
        color: $primary-700;
        background: $primary-100;
      }

      &.Correct {
        background-color: #66cc66;
      }

      &.PartlyCorrect {
        background-color: #cc9900;
      }

      &.Ignored {
        background-color: #f0eeee;
        color: #999;
        text-decoration: line-through;
      }

      &.active {
        background: $primary-500;
        color: $white;
      }

      &:hover {
        text-decoration: none;
      }

      &.item-instruction {
        &::before {
          content: '';
          margin-top: -2px;
          @include base64image('i-letter');
          background-repeat: no-repeat;
          width: 8px;
          height: 17px;
        }

        &.active::before {
          @include base64image('i-letter-white');
        }
      }
    }

    &:not(.mega) {
      .item-link {
        @include navigationStyling();
      }
    }

    &.mega {
      .item-link {
        width: 21px;
        height: 21px;
        font-size: 11px;
        @include navigationStyling(true);

        &.item-instruction {
          &::before {
            width: 6px;
            height: 12px;
            background-size: contain;
          }
        }
      }
    }

    .instructions-container {
      .legend-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: $px-8;
        width: 35px;
        height: 28px;
        font-size: $px-14;
        font-weight: 700;
        color: $gray-700;
        border: 1px solid $gray-200;
        border-radius: 0;
        background-color: $white;

        &:first-child {
          margin-left: auto;
        }

        &.OK {
          color: $primary-700;
          background: $primary-100;
        }

        &.active {
          background: $primary-500;
          color: $white;
        }

        &.flagged:after {
          content: '';
          position: absolute;
          top: 0;
          right: -1px;
          border-left: 20px solid transparent;
          border-top: 16px solid $warning-500;
          z-index: 100;
        }
      }
    }
  }

  .test-page {
    display: flex;
    flex-direction: column;
    flex: 1;

    .item-group {
      display: flex;
      flex-direction: column;
      flex: 1;

      &:only-child {
        min-height: 100%;

        .item:last-child {
          flex: 1;
        }
      }

      .item-group-items {
        display: flex;
        flex-direction: column;
        flex: 1;

        &:only-child {
          min-height: 100%;

          .item:last-child {
            flex: 1;
          }
        }

        &:last-child {
          .item:last-child {
            flex: 1;
            padding-bottom: $px-120;
          }
        }
      }

      &:not(:first-child) {
        margin-top: $px-30;
      }

      &:not(:last-child) {
        .item-group-items {
          .item:last-child {
            padding-bottom: $px-30;
          }
        }
      }
    }

    &.layout-Column2ItemsLeft,
    &.layout-Column2ItemsRight {
      @include breakpoint('small') {
        .item-group {
          display: block;

          &:only-child {
            padding-bottom: $px-60;
          }

          &:last-child {
            .item:last-child {
              padding-bottom: $px-60;
            }
          }

          .item {
            width: calc(50% - 8px);

            &:last-child {
              margin-bottom: 0;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }

            &.implicit-instruction {
              margin-bottom: $px-16;
              width: 100%;
            }

            &.shared-item {
              margin-bottom: 0;
              width: calc(50% - 8px);
              max-height: calc(100vh - $fixedComponentsHeight);
              overflow: auto;
              height: 100%;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              @include scrollbar();
            }

            .rich-content table {
              @include scrollbar();
            }
          }

          .item-group-items {
            width: calc(50% - 8px);
            max-height: calc(100vh - $fixedComponentsHeight);
            height: 100%;
            overflow: auto;
            @include scrollbar();

            .item {
              width: calc(100%);
            }
          }
        }
      }
    }

    &.layout-Column2ItemsLeft {
      @include breakpoint('small') {
        .item {
          &.shared-item {
            float: right;
            margin-left: $px-16;
          }
        }
      }
    }

    &.layout-Column2ItemsRight {
      @include breakpoint('small') {
        .item {
          &.shared-item {
            float: left;
          }
        }

        .item-group-items {
          float: right;
          margin-left: $px-16;
        }
      }
    }

    &.layout-Horizontal {
      .item-group {
        &:last-child {
          .item-group-items {
            &:last-child {
              .item:last-child {
                flex: 0;
                padding-bottom: $px-24;
                margin-bottom: $px-120;
              }
            }
          }
        }
      }
    }

    &.layout-Vertical {
      @include breakpoint('small') {
        .item-group {
          height: 100%;
          flex-basis: content;

          .shared-item {
            max-height: calc(80vh - $fixedComponentsHeight);
            overflow: auto;
            @include scrollbar();
          }

          .item-group-items {
            display: flex;
            flex-direction: row;
            align-content: space-between;
            gap: 10px;

            .item {
              flex: 1;
              min-height: 100%;
            }
          }
        }
      }
    }

    // gradient only in 2-columns layout with half width
    &.layout-Column2ItemsLeft,
    &.layout-Column2ItemsRight {
      .shared-item {
        padding-bottom: $px-50;
      }
    }
  }

  #buttons-row {
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: start;
    width: 100%;
    height: $buttons-row-height;
    z-index: 401;
    background-color: $white;
    padding: $px-15 $px-30;
    box-shadow: 0px -4px 6px -2px rgba(16, 24, 40, 0.03),
      0px -12px 16px -4px rgba(16, 24, 40, 0.08);

    button {
      grid-column: 1;
      display: flex;
      justify-content: center;
      min-width: 138px;
      padding: $px-10 $px-18;
      max-height: 44px;
      z-index: 403;

      &#scrollMore {
        grid-column: 2;
        justify-self: center;
        color: $gray-700;
        position: relative;
        top: -48px;
        cursor: default;

        .test-continues-polygon {
          position: absolute;
          top: 155px;
          margin: 5rem auto;
          width: 80rem;
          height: 80rem;
          background-color: $white;
          border-radius: 20rem 1rem 300rem 1rem;
          transform: rotate(45deg);
          box-shadow: 0px -4px 6px -2px rgba(16, 24, 40, 0.03),
            0px -12px 16px -4px rgba(16, 24, 40, 0.08);
        }
        .test-continues-overlay {
          position: absolute;
          top: 32px;
          background-color: $white;
          width: 300px;
          height: 70px;
          z-index: 402;
        }
        .arrow-down-toggle {
          position: absolute;
          margin-top: 15px;
          display: inline-block;
          padding: 4px;
          border-radius: 50%;
          z-index: 403;
          cursor: pointer;
          background-color: $page-background-color;

          .arrow-down-active {
            display: none;
          }

          &:hover .arrow-down {
            display: none;
          }

          &:hover .arrow-down-active {
            display: inline-block;
          }
        }

        &:focus:not(#scrollMore),
        &:active:not(#scrollMore) {
          border: none;
          box-shadow: none;
        }
      }

      &#btnNext:disabled,
      &#btnClose:disabled {
        img {
          animation: $spin-linear;
        }
      }

      &:last-of-type {
        grid-column: 3;
        justify-self: end;
      }
    }
  }

  .instructions-container {
    display: flex;
    flex-direction: column;

    .testInstructions {
      ul {
        margin: 0;
        padding-left: $px-16;

        li {
          font-size: $px-14;
          line-height: $px-20;
          color: $gray-600;
        }
      }
    }

    .instructions-title {
      margin-bottom: $px-20;
      font-size: $px-16;
      font-weight: 500;
      color: $gray-900;
      line-height: $px-24;
    }

    .instructions-legend {
      padding-bottom: $px-20;
    }

    .instructions-html {
      margin-top: 15px;
    }

    .legend-row {
      margin-bottom: $px-12;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $px-14;
      line-height: $px-20;
      font-weight: 500;
    }
  }

  .flagged {
    position: relative;
  }

  .item-link.flagged {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      border-left: 20px solid transparent;
      border-top: 16px solid $warning-500;
      z-index: 100;
    }

    &.active:after {
      right: 0;
    }
  }

  .flag {
    margin-left: auto;
    padding: 0 0 0 30px;
    color: $primary-700;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    background-position-x: left;
    background-position-y: calc(50% - 2px);
    @include base64image('star');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    border: 0;

    &.flagged {
      color: $gray-700;
      @include base64image('star-filled');
    }
  }

  .media-message {
    margin-top: $px-12;
    padding: $px-16;
    padding-left: $px-52;
    color: $warning-700;
    font-size: 75%;
    line-height: $px-18;
    font-weight: 500;
    border: 1px $warning-300 solid;
    border-radius: $px-8;
    background-color: $warning-25;
    background-position-x: $px-16;
    background-position-y: 50%;
    @include base64image('alert');
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  audio,
  video {
    max-width: 100%;
  }

  video {
    display: block;
    width: 100%;
    height: auto;
    border-radius: $px-8;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include breakpoint('normal') {
      width: 80%;
      margin-inline: 10%;
    }

    @include breakpoint('large') {
      width: 60%;
      margin-inline: 20%;
    }
  }

  .audio-overlay,
  .video-overlay {
    display: flex;
    align-items: center;
    padding: $px-16 $px-32;
    gap: $px-16;
    z-index: 400;

    .play-pause {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      @include base64image('play');
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .time {
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      width: $px-84;
      font-size: $px-14;
    }

    .timeline-container {
      width: 100%;
      height: 8px;
      border-radius: 8px;
      background-color: $gray-200;
      cursor: pointer;

      .timeline {
        height: 8px;
        border-radius: 8px;
        background-color: $primary-600;
      }
    }

    &.restricted {
      .timeline-container {
        cursor: not-allowed;
      }

      &.overflow,
      &.playing {
        .play-pause {
          cursor: not-allowed;
          @include base64image('pause-gray');
        }
      }
    }
  }

  .audio-overlay,
  .video-overlay {
    height: $px-60;
    border: 1px $gray-200 solid;
    border-radius: 16px;

    &.playing {
      .play-pause {
        @include base64image('pause');
      }
    }
  }

  .video-overlay {
    align-items: flex-end;
    height: auto;
    gap: 0;
    border-radius: $px-8;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @include breakpoint('normal') {
      width: 80%;
      margin-inline: 10%;
    }

    @include breakpoint('large') {
      width: 60%;
      margin-inline: 20%;
    }

    .video-controls {
      display: flex;
      width: 100%;
      gap: $px-16;
      align-items: center;

      .fullscreen {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        @include base64image('fullscreen');
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
  }

  &.highlighter-active {
    .assignment,
    .text-item {
      cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9u ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwo I2NsaXAwXzE0NTFfMTEwMTQpIj4KPHBhdGggZD0iTTMwLjM5NCA2LjMwMDVMMjguMzU1NyA0LjI5 ODA5TDI2LjMyODYgMi4yODM4OUMyNi4xNjU5IDIuMTIyMDIgMjUuOTUwMyAyLjAyMjYgMjUuNzIw MiAyLjAwMzQxQzI1LjQ5MDIgMS45ODQyMiAyNS4yNjA3IDIuMDQ2NSAyNS4wNzI4IDIuMTc5MTJM Ni4yNjM3NCAxNS40NTQxQzYuMTM3MTcgMTUuNTQzMyA2LjAzNDEgMTUuNjYxMyA1Ljk2MzE5IDE1 Ljc5OEM1Ljg5MjI4IDE1LjkzNDcgNS44NTU1OSAxNi4wODYyIDUuODU2MiAxNi4yMzk5VjIxLjkw MDFDNS44NTYyNSAyMi4wMjY2IDUuODMxMDQgMjIuMTUxOCA1Ljc4MjAzIDIyLjI2ODdDNS43MzMw MiAyMi4zODU1IDUuNjYxMTcgMjIuNDkxNyA1LjU3MDU5IDIyLjU4MTFMMC4yODc4MTkgMjcuNzky QzAuMTk0MjUyIDI3Ljg4MjEgMC4xMjA0MzIgMjcuOTkwMSAwLjA3MDk1NDUgMjguMTA5NUMwLjAy MTQ3NjkgMjguMjI4OSAtMC4wMDI2MDAwMyAyOC4zNTcxIDAuMDAwMjIyMzEzIDI4LjQ4NjFDMC4w MTA4MjUgMjguOTk3NiAwLjQ1NDE0OCAyOS4zOTcgMC45NzIzNTMgMjkuMzk3SDYuNzI3NkM3LjA1 NjczIDI5LjM5NjggNy4zNzIzMiAyOS4yNjc1IDcuNjA0OTcgMjkuMDM3NUw5Ljg1ODA0IDI2Ljgx MTFDMTAuMDQwOCAyNi42MzA1IDEwLjI4ODcgMjYuNTI5IDEwLjU0NzIgMjYuNTI4OUgxNi4yNzUz QzE2LjQzMDggMjYuNTI5NyAxNi41ODQyIDI2LjQ5MzUgMTYuNzIyNiAyNi40MjM0QzE2Ljg2MSAy Ni4zNTMzIDE2Ljk4MDMgMjYuMjUxNCAxNy4wNzA1IDI2LjEyNjJMMzAuNDk5NCA3LjU0MTM3QzMw LjYzMzcgNy4zNTU4MiAzMC42OTY5IDcuMTI5MTIgMzAuNjc3NSA2LjkwMThDMzAuNjU4MiA2LjY3 NDQ3IDMwLjU1NzggNi40NjEzNCAzMC4zOTQgNi4zMDA1Wk02Ljc0MTUyIDI4LjIwNTJIMS41NzYw NEw0Ljk0MzcxIDI0Ljg4MzRMNy41MjI4IDI3LjQzMTlMNi43NDE1MiAyOC4yMDUyWk0xNy4xODA1 IDIzLjkxNjlMMTQuNDgwMSAyMS4yNTExTDguNTAwOSAxNS4zNDI4TDkuNDk0OSAxNC42NDA4TDEx LjkyMDkgMTcuMDM3NEwxNC45MTAyIDE5Ljk4OTNMMTUuMzMwMyAyMC40MDUxTDE3Ljg5MjIgMjIu OTMzM0wxNy4xODA1IDIzLjkxNjlaIiBmaWxsPSJibGFjayIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBh dGggaWQ9ImNsaXAwXzE0NTFfMTEwMTQiPgo8cmVjdCB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZp bGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==')
          0 32,
        auto;

      :not(textarea, input)::selection {
        background-color: $highlighter-selected-color;
      }

      .highlight-wrapper {
        background-color: $highlighter-selected-color;
        cursor: grab;
      }
    }
  }
}
