.component-app {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.component-app:focus {
    outline: none;
}

.component-app button {
    border: 1px solid transparent;
    margin: 0 1px 0 0;
    flex: 1 0 auto;
    cursor: pointer;
}

.component-app button:focus {
    outline: 0;
}
