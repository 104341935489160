.component-button-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 auto;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 2px;
    position: relative;
}

.component-button-panel > div {
    width: 100%;
    margin-bottom: 1px;
    flex: 1 0 auto;
    display: flex;
}

span.unit-switcher {
    font-size: 24px;
    position: relative;
    top: -3px;
}